import store from '../store'

const wizardGuard = (to, from, next) => {
    const query_questions = to.query.questions
    var questions;
    if (query_questions && store.getters.topLevelQuestions) { // Edit case
        questions = store.getters.topLevelQuestions.filter(item => query_questions.indexOf(item.key) !== -1)
        next()
    } else { // Miss questions case
        questions = store.getters.missedQuestions
        if (questions.length) next()
        else next({ name: 'home', query: to.query })
    }
    return questions
}

const adviceGuard = (to, from, next) => {
    if (store.getters.missedQuestions.length) {
        store.dispatch('setBackRoute', to)
        next({ name: 'wizard', query: to.query })
    } else next()
}

export {
    wizardGuard,
    adviceGuard
}
