<template>
    <div v-if="lab" :class="{ disabled }">
        <w-lab-header :date="date('yyyy-MM-dd')" class="pr-3 ml-3"/>
        <w-recommendations
            class="pr-3 ml-3"
            :products="labProducts"
            :outdated="disabled"
            :time="date('yyyy-MM-dd', 21)"
            :link="recommendationsLink"/>
        <template v-for="item in items">
            <div class="lab-box" :key="item.id">
                <w-lab-item :item="item" @click-modal="showModal(item)" @click-advice="showAdvice(item)"/>
            </div>
            <w-separator class="mx-0" :key="`sep_${item.id}`"/>
        </template>
        <div class="d-flex justify-content-between">
            <w-button v-if="isBack" @click="$router.go(-1)" type="circle-primary" class="yummo-bold m-4" :to="{name: 'home'}">{{ $t("button.back") }}</w-button>
            <!-- <w-button @click="openPrev = true" type="circle-primary" class="yummo-bold m-4 float-right">Previous results</w-button>-->
        </div>
        <w-modal :open="open" position="bottom" :cancel-text="$t('button.close')" :show-back="false" @cancel="selectedItem = null">
            <p v-if="selectedItem"><translate :text="selectedItem.desc"/></p>
        </w-modal>
        <w-modal :open="openPrev" @cancel="openPrev = false" :show-back="false">

        </w-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { format, addDays } from 'date-fns'
import { DOMAIN, MODE } from '../amplify/config'
import {GA_WATERGURU_TEST_ITEM} from "../constants/ga_events";
import moment from 'moment';
import Translate from '../components/Translate'


export default {
    name: 'LabResults',
    components: { Translate },
    data () {
        return {
            selectedItem: null,
            openPrev: false
        }
    },
    computed: {
        ...mapGetters(['lab', 'labProducts', 'buyProductsLink']),
        items () {
            return this.$store.getters.labMeasurements
            .map(item => ({
                id: item.type,
                title: item.title,
                alert: item.alerts && item.alerts[0] && item.alerts[0].advice && item.alerts[0].advice.action ? item.alerts[0].advice.action.summary : '',
                desc: item.cfg.desc,
                value: parseFloat(item.value),
                advice: (item.alerts && item.alerts[0] && item.alerts[0].advice),
                ranges: item.cfg.floatRanges || item.cfg.intRanges
            }));
        },
        open () {
            return !!this.selectedItem
        },
        isBack () {
            return this.$store.getters.startRoute && this.$store.getters.startRoute.name === 'home'
        },
        disabled () {
            return moment().diff(moment(this.lab.labResultsTime), 'months') > 0;
        },
        recommendationsLink () {
            // NOTE: disabling "Order TEST" button to order WaterGuru TEST kit (customers can still do that after tapping the Test button
            // in the app dashboard, except other tenants like Purezone app; thus, we don't provide it in Advice any longer)
            //
            // return this.disabled ? `${DOMAIN}/cart/add?id[]=${MODE === 'prod' ? waterguru_test_product.id : waterguru_test_product.id_qa}` : this.buyProductsLink
            return this.disabled ? '' : this.buyProductsLink
        }
    },
    methods: {
        showModal (item) {
            this.selectedItem = item
        },
        showAdvice (item) {
            this.$gtag.event(GA_WATERGURU_TEST_ITEM, {title: item.title});
            this.$gtag.event(`Check ${item.title} Advice`);
            this.$router.push({ name: 'advice', params: { category: item.id.toLowerCase() } }).catch(e => e)
        },
        date (_format, add = 0) {
            return this.lab.labResultsTime ? format(addDays(new Date(this.lab.labResultsTime), add), _format) : null
        }
    }
}
</script>

<style scoped lang="scss">
    .disabled {
        .lab-box {
            opacity: .5;
            pointer-events: none;

            * {
                pointer-events: none;
            }
        }
    }
</style>
