import Error from '../views/Error'
import Home from '../views/Home'
import Advice from '../views/Advice'
import Wizard from '../views/Wizard'
import LabResults from '../views/LabResults'

// const Error = () => import('../views/Error')
// const Home = () => import('../views/Home')
// const Advice = () => import('../views/Advice')
// const Wizard = () => import('../views/Wizard')
// const LabResults = () => import('../views/LabResults')

// NOTE: "&locale=en" or "&locale=fr" will be added to .query, and then will be used in filter.js#beforeFilter() to swithc i18n locale
export default [
    {
        path: '/',
        name: 'home',
        props: true,
        meta: {
            pool: true,
            auth: 'user',
            header: true
        },
        component: Home
    },
    {
        path: '/advice/:category',
        name: 'advice',
        props: true,
        meta: {
            pool: true,
            auth: 'user',
            header: true
        },
        component: Advice
    },
    {
        path: '/wizard',
        name: 'wizard',
        props: true,
        meta: {
            pool: true,
            auth: 'user',
            header: true
        },
        component: Wizard
    },
    {
        path: '/lab-results',
        name: 'lab-results',
        props: true,
        meta: {
            pool: true,
            auth: 'user',
            header: true
        },
        component: LabResults
    },
    {
        path: '/error',
        name: 'error',
        props: true,
        meta: {
            auth: 'guest',
            header: true
        },
        component: Error
    },
    {
        path: '*',
        redirect: { name: 'home' }
    }
]
