import Cookies from 'js-cookie'
import Auth from '@aws-amplify/auth'
import { ADD_POOL, POOL_NOT_FOUND, UNAUTHENTICATED, WB_ID_MISSING } from '../store/error-messages'
import sendToSlack from '../helpers/sendToSlack'
import { isValidUuid } from '../helpers'
import { lambda } from '../amplify'
import store from '../store'
import i18n from '../i18n'


const COOKIE_NAMES = ['refreshToken', 'accessToken', 'idToken', 'deviceKey']

const getUser = () => {
    console.log("*** getUser(), cookies = ", Cookies.get())
    return new Promise((resolve) => {
        const cookies = Cookies.get()
        const missedCookie = COOKIE_NAMES.find(item => !Object.keys(cookies)
            .some(key => key.includes(item) && cookies[key]))
        if (missedCookie) {
            store.dispatch('setError', `Missing ${missedCookie} cookie`)
            resolve(false)
        } else {
            Auth.currentAuthenticatedUser().then(user => {
                resolve(user)
            }).catch((err) => {
                store.dispatch('setError', UNAUTHENTICATED)
                sendToSlack(err, {}, cookies)
                resolve(false)
            })
        }
    })
}

let counter = 0
const getPool = (userId, poolId, tenant) => {
    return new Promise((resolve) => {
        if (!isValidUuid(poolId)) {
            store.dispatch('setError', WB_ID_MISSING)
            resolve(false)
            return
        }

        const call = () => {
            lambda('getDashboardView', {
                userId: userId,
                locale: i18n.locale,
                tenant: getTenantForLambda(tenant),
            }).then(res => {
                if (!res.waterBodies || !res.waterBodies.length || !res.waterBodyIdToIndex || !Object.keys(res.waterBodyIdToIndex).length) {
                    store.dispatch('setError', ADD_POOL)
                    resolve(false)
                } else if (res.waterBodyIdToIndex[poolId] === undefined) {
                    store.dispatch('setError', POOL_NOT_FOUND)
                    resolve(false)
                } else {
                    const index = res.waterBodyIdToIndex[poolId]
                    resolve({
                        pools: res.waterBodies.map(item => ({ id: item.waterBodyId, name: item.name })),
                        pool: res.waterBodies[index],
                        colors: res.statusColors
                    })
                    counter = 0
                }
            }).catch(() => {
                counter++
                if (counter <= 3) {
                    call()
                } else {
                    store.dispatch('setError', 'Something went wrong')
                    resolve(false)
                }
            })
        }

        call()
    })
}

const getLabResults = (userId, poolId, tenant) => {
    return new Promise((resolve) => {
        lambda('getLabView', {
            userId: userId,
            locale: i18n.locale,
            tenant: getTenantForLambda(tenant),
        }).then(res => {
            if (res.waterBodies && res.waterBodies.length && res.waterBodyIdToIndex && Object.keys(res.waterBodyIdToIndex).length && res.waterBodyIdToIndex[poolId] !== undefined) {
                resolve(res.waterBodies[res.waterBodyIdToIndex[poolId]])
            } else {
                resolve(false)
            }
        }).catch(() => {
            resolve(false)
        })
    })
}

const getHistory = (podId, startTime, endTime, tenant, maxResults = 200) => {
    return lambda('managePod', {
        locale: i18n.locale,
        tenant: getTenantForLambda(tenant),
        queryDataHistoryReq: {
            podIds: [podId],
            maxResults,
            startTime,
            endTime,
            returnCsv: false,
        }
    }).then(data => {
        return data.queryDataHistoryRsp.data
    }).catch(err => {
        console.log(err)
    })
}

function getTenantForLambda(tenant) {
    return tenant ? tenant.toUpperCase() : null;
}

export {
    getUser,
    getPool,
    getLabResults,
    getHistory
}
