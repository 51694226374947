import Cookie from 'js-cookie'
import { isAfter } from 'date-fns'
import { DOMAIN } from '../amplify/config'

// TODO: remove this cookie product_suggestions that seems to be an optimization to store recommended products in the cookie (for 30 minutes)
// NOTE: in QA we observed this cookie was set to "[object Object]" literal string and thus failed at JSON.parse(), thus Advice page failed completely
const productSuggestions = null; // Cookie.get('product_suggestions') ? JSON.parse(Cookie.get('product_suggestions')) : null
// TODO: remove this cookie, seems to be never set (perhaps in Shopify? But very, very unlikely, waiting for the confirmation)
const productsBought = Cookie.get(' ') ? JSON.parse(Cookie.get('products_bought')) : []
const exploded = DOMAIN.split('.')
const cookieDomain = '.' + exploded.slice(exploded.length - 2,3).join('.')

const initCookies = (date, allLabProducts) => {
    if (!productSuggestions || isAfter(new Date(date), new Date(productSuggestions.date))) {
        Cookie.set('product_suggestions', {
            date, ids: allLabProducts.map(({ id }) => id).join(',')
        }, { expires: 30, domain: cookieDomain })
    }
    return productSuggestions || { date, ids: allLabProducts.map(({ id }) => id).join(',') }
}

export {
    initCookies,
    productSuggestions,
    productsBought
}
