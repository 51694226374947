<template>
    <div class="wizard-container">
        <template v-if="current !== -1 && currentQuestion">
            <div class="px-3 pb-3">
                <template v-if="!$route.query.questions">
                    <p><translate :text="'We want to get the most accurate and helpful advice your way. To do that, we just need a little more information.'"/></p>
                    <wizard-progress :count="questions.length" :current="current"/>
                </template>
                <h3><translate :text="currentQuestion.title"/></h3>
                <p v-if="currentQuestion.desc"><translate :text="currentQuestion.desc"/></p>
            </div>

            <w-select-list v-if="currentQuestion.type === 'select' || currentQuestion.type === 'select_input'">
                <li v-for="(option, index) in currentQuestion.questions" :key="`${current}_${option.id}_${index}`"
                    :class="{active: selectValue === option.id}" @click.self="select(option)">
                    <div class="flex-grow-1" @click="select(option)">
                        <translate :text="option.name"/>
                        <span v-if="option.related && option.related.length && wb[option.related[0]] && selectValue === option.id"> = {{ wb[option.related[0]] }}%</span>
                    </div>
                    <div class="edit" @click="edit = option.related" v-if="option.related && option.related.length && selectValue === option.id">
                        {{ $t("questions.edit") }}
                        <fa class="font-sm" icon="chevron-right"/>
                    </div>
                </li>
            </w-select-list>
            <validation-provider v-show="showInput" id="inputBox" style="padding: 15px 20px" tag="div" ref="observer" v-slot="{ errors, invalid }" :name="currentQuestion ? currentQuestion.key : ''">
<!--                <div>-->
                    <input required ref="input" :placeholder="currentQuestion.label"
                           :type="currentQuestion.input_type" :name="currentQuestion.key" :class="{'is-invalid': errors[0]}"
                           :min="currentQuestion.input_min" :max="currentQuestion.input_max" :step="currentQuestion.input_step"
                           v-model="inputValue">
                    <div :class="errors[0] ? 'd-block' : 'd-none'" class="invalid-feedback">
                        <translate :text="currentQuestion.custom_error || errors[0]"/>
                    </div>
<!--                </div>-->
            </validation-provider>
        </template>

        <div class="px-3 pb-3" v-if="current === -1">
            <p class="mb-5"><translate :text="'We’ve got a good beed on your equipment and how we can advise you going forward.'"/></p>
            <img class="d-block mx-auto" height="196px" width="137" src="../assets/images/Group-1352.svg" alt="">
        </div>
        <wizard-buttons :show-back="!!($route.query.questions && $store.getters.prevRoute && $store.getters.prevRoute.name === 'advice')"
                        :disable-next="disableNext"
                        @next="next"
                        @back="$router.push($store.getters.prevRoute)">
            <template v-slot:next>
                <translate :text="current === -1 ? 'Onward to Advice' : 'Save + continue'"/>
            </template>
            <template v-slot:back>
                {{ $t("button.back") }}
            </template>
        </wizard-buttons>

        <sub-sequent-modal :open="!!edit" :question="relatedQuestion" @cancel="cancelModal" @saved="cancelModal"/>
    </div>
</template>

<script>
import { lambda } from '../amplify'
import { wizardGuard } from '../helpers'
import SubSequentModal from '../components/SubSequentModal'
import { ValidationProvider } from 'vee-validate'
import {GA_COMPLETE_QUESTIONNAIRE, GA_EDIT_SETTINGS} from "../constants/ga_events";
import Translate from '../components/Translate'
import store from '@/store/index.js';  // import the store to be used in beforeRouteEnter() where this.$store is not available

let questions = []

export default {
    name: 'Wizard',
    components: { SubSequentModal, ValidationProvider, Translate },
    data () {
        return {
            questions: [...questions],
            selectValue: '',
            inputValue: '',
            current: 1,
            waterBody: {},
            edit: false,
            processing: false
        }
    },
    computed: {
        currentQuestion () {
            return this.questions[this.current - 1]
        },
        disableNext () {
            const waterBody = this.wb
            const answer = this.currentQuestion ? this.currentQuestion.questions.find(item => item.id === this.selectValue) : null
            return this.processing || this.currentQuestion && (this.current !== -1 && (
                (this.currentQuestion.type === 'select' && !this.selectValue)
                || (this.currentQuestion.type === 'input' && !this.inputValue)
                || (this.currentQuestion.type === 'select_input' && !this.selectValue && !this.inputValue)))
                || (answer && answer.related && !waterBody[answer.related[0]])
                || this.showInput && this.inputValue && this.$refs.observer.errors && !!this.$refs.observer.errors.length
        },
        relatedQuestion () {
            const questions_all = this.$store.getters.questions
            return questions_all.find(item => item.key === this.edit[0])
        },
        wb () {
            return this.$store.getters.waterBody
        },
        showInput () {
            const option = this.currentQuestion ? this.currentQuestion.questions.find(item => item.value === '[INPUT]') : null
            return option && this.selectValue === option.id
        }
    },
    methods: {
        async next () {
            this.processing = true
            try {
                if (this.current === -1) {
                    const nextRoute = this.$store.getters.backRoute
                    this.$router.push(nextRoute || { name: 'home' }).catch(e => e)
                    return
                }
                let payload = {
                    waterBody: {
                        userId: this.$route.query.user || this.$store.getters.userId,
                        waterBodyId: this.$route.query.wb_id
                    }
                }

                if (this.currentQuestion && this.currentQuestion.type === 'select') {
                    const answer = this.currentQuestion.questions.find(({ id }) => id === this.selectValue)
                    payload.waterBody = {
                        [this.currentQuestion.key]: answer.value,
                        ...payload.waterBody,
                        ...answer.more
                    }
                } else if (this.currentQuestion && this.currentQuestion.type === 'input') {
                    payload.waterBody = {
                        [this.currentQuestion.key]: this.inputValue,
                        ...payload.waterBody
                    }
                } else if (this.currentQuestion && this.currentQuestion.type === 'select_input') {
                    const answer = this.currentQuestion.questions.find(({ id }) => id === this.selectValue)
                    payload.waterBody = {
                        [this.currentQuestion.key]: answer.value === '[INPUT]' ? this.inputValue : answer.value,
                        ...payload.waterBody
                    }
                }
                payload.waterBody = Object.keys(payload.waterBody).reduce((acc, key) => {
                    payload.waterBody[key]
                    return { [key]: payload.waterBody[key] === '' ? null : payload.waterBody[key], ...acc }
                }, {})

                if (this.currentQuestion.key === 'userCl'){
                    this.$gtag.event('Edited Chlorine Type');
                }

                if (this.currentQuestion.key === 'userAcid'){
                    this.$gtag.event('Edited Acid Type');
                }

                const { code } = await lambda('updateWaterBody', payload)

                // NOTE: since we updated water body, we need to re-query the data from the cloud again
                // For simplicity, we clear the state and filter.js#beforeFilter() will re-query data automatically
                this.$store.commit('CLEAR_WITHOUT_ROUTES');

                if (code === 'OK' && this.current === this.questions.length && !this.$route.query.questions) {
// NOTE: we no longer show titles
//                    this.$store.dispatch('setTitle', 'All done!')
                    this.current = -1
                    this.$gtag.event('Finished Setup Questionnaire');
                    this.$gtag.event(GA_COMPLETE_QUESTIONNAIRE);
                } else if (code === 'OK' && this.current === this.questions.length && this.$route.query.questions) {
                    const nextRoute = this.$store.getters.prevRoute
                    this.$router.push(nextRoute || { name: 'home' }).catch(e => e)
                    this.$gtag.event(GA_EDIT_SETTINGS);
                } else if (code === 'OK') {
                    // TODO: do we need this? After updateWaterBody Lambda, we don't navigate but just increase counter
                    await this.$store.dispatch('init', { wbId: this.$route.query.wb_id, userId: this.$route.query.user })

                    this.current++
                    this.selectValue = null
                    this.inputValue = null
                }
            } finally {
                this.processing = false
            }
        },

        select (option) {
            this.selectValue = option.id
            if (option.related && !this.wb[option.related[0]]) {
                this.edit = option.related || false
            } else {
                this.edit = false
            }
            if (this.edit) this.inputValue = this.wb[option.related[0]]
        },

        cancelModal () {
            if (this.relatedQuestion && !this.wb[this.relatedQuestion.key]) {
                this.selectValue = ''
                this.inputValue = ''
            }
            this.$nextTick().then(() => {
                this.edit = false
            })
        },

        setValue () {
            if (!this.currentQuestion) return
            const waterBody = this.wb
            const str_value = waterBody[this.currentQuestion.key]
            const answer = this.currentQuestion.questions.find(({ more, value }) => {
                return value === str_value && (!more || !Object.keys(more).length || Object.keys(more)
                .every(key => waterBody[key] === more[key]))
            })
            if (str_value === undefined) return
            if (this.currentQuestion.type === 'select') {
                this.select(answer || { id: '' })
            } else if (this.currentQuestion.type === 'input') {
                this.inputValue = str_value
            } else if (this.currentQuestion.type === 'select_input') {
                this.select(answer || this.currentQuestion.questions.find(({ value }) => value === '[INPUT]'))
                this.inputValue = !answer ? str_value : ''
            }
        }
    },
    watch: {
        current: {
            handler () {
                this.setValue()
            }
        }
    },
    created () {
        this.setValue()
    },
	mounted () {
        if (!this.$route.query.questions){
            this.$gtag.event('Started Setup Questionnaire');
        }

		this.$gtag.config({
			send_page_view: false
		})

		this.$gtag.event('page_view', {
			page_title: 'WG Advice - Wizard',
			page_location: window.location.href,
			page_path: window.location.pathname
		})
	},
    beforeRouteEnter (...args) {
        questions = wizardGuard(...args)
    },
    beforeRouteUpdate (...args) {
        questions = wizardGuard(...args)
    }
}
</script>

<style scoped lang="scss">
    .wizard-container {
        padding-bottom: 100px;
    }

    .edit {
        cursor: pointer;
        min-width: 40px;
    }
</style>

